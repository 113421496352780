@font-face {
  font-family: Montserrat-regular;
  src: url('./resources//font/Montserrat-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: Sacramento;
  src: url('./resources/font/Sacramento-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: DancingScript;
  src: url('./resources/font/DancingScript-VariableFont_wght.ttf');
  font-weight: normal;
}

@font-face {
  font-family: PonyonScript;
  src: url('./resources/font/PinyonScript-Regular.ttf');
  font-weight: normal;
}

.App {
  text-align: center;
}


/* Menu styles */
.menu {
  margin-bottom: 2% !important;
}
.menu-header{
  font-family: Sacramento, sans-serif !important;
  font-size: 80px !important;
  text-align: center;
  color: #a88b70 !important;
  margin-top: 2% !important;
}

.menu-item {
  height: fit-content;
  margin-top: auto !important;
  margin-bottom: auto !important;
  width: fit-content !important;
}

.menu-item {
  font-size: 20px !important;
  font-family: Montserrat-regular !important;
}

.menu-items-group {
  margin-right: 5% !important;
}


/* Landing styles */
.landing-main {
  border-radius: 0 !important;
  min-height: fit-content;
  width: 100% !important;
}

.landing-main-header, .landing-main-mom {
  margin-top: 2% !important;
  font-size: 65px !important;
  /*font-family: Montserrat-regular !important;*/
  font-family: PonyonScript !important;
}

.landing-main-mom {
  display: inline;
  /*color: #fffa6e !important;*/
  color: #a88b70 !important;
  font-size: 65px !important;
}

.landing-main-description {
  font-size: 20px !important;
  margin: 2% 10% !important;
  font-family: Montserrat-regular !important;
}

.landing-main-pictures {
  margin-top: 4% !important;
  margin-bottom: 3% !important;
}


/* Funeral Info style */

.funeral-info-message {
  margin: 5% !important;
  background-color: #f0e4d9 !important;
}

.funeral-info-container {
  font-size: 18px;
  margin: 2% 10% !important;
}

.funeral-info-meeting-id {
  margin-left: auto !important;
  margin-right: auto !important;
}

.funeral-info-divider {
  margin-left: 5% !important;
  margin-right: 5% !important;
}


/* Portait styles */
.portrait {
  background-color: transparent !important;
  min-height: 38em !important;
  color: white !important;
  margin-top: 1em !important;
}

.portrait-picture {
  border: 3px solid white !important;
}

.portrait-name, .portrait-date {
  color: white !important;
  font-family: Montserrat-regular, sans-serif !important;
}

.portrait-name {
  font-size: 40px !important;
}

.portrait-date {
  margin-top: 1% !important;
}

.portrait-header {
  font-family: DancingScript, sans-serif !important;
  font-size: 30px !important;
  margin-bottom: 2% !important;
}


/* Gallery styles */
.gallery-picture {
  margin-bottom: 2em !important;
  border-radius: 8px !important;
  /*box-shadow: 0 0 10px 2px gray !important*/
}

.gallery-header {
  margin-top: 10% !important;
  font-size: 60px !important;
  font-family: Montserrat-regular !important;
}

.gallery-description {
  font-family: Montserrat-regular !important;
  color: gray !important;
  font-size: 25px !important;
  margin-bottom: 15% !important;
}

.gallery-grid {
  margin-top: 3% !important;
}


/* About styles */
.about-segment-1-header-h1 {
  margin-top: 2% !important;
  font-family: Montserrat-regular !important;
  font-size: 50px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.about-segment-1, .about-segment-2, .about-segment-3, .about-segment-4 {
  margin-top: 0% !important;
  margin-bottom: 0% !important;
  border-radius: 0 !important;
  border: none !important;
  min-height: fit-content !important;
  padding-bottom: 4% !important;
}

.about-segment-2, .about-segment-4 {
  background-color: #e4d9cf !important;
  min-height: fit-content !important;
}

.about-segment-3-notes-container { /* Needs to be changed!! */
margin-top: 4% !important;
}

.about-segment-1-header-h3 {
  font-family: PonyonScript !important;
  font-size: 25px !important;
  color: #ad937a !important;
}

.about-segment-1-header-h4 {
  font-family: Montserrat-regular !important;
  font-size: 25px !important;
}

.about-segment-1-grid {
  margin-top: 2% !important;
}

.about-early-life, .about-work {
  font-size: 20px;
}

/* Segment 2 styles*/
.about-segment-2-picture {
  border: 15px white double;
  margin-bottom: 4%;
}

.about-segment-2-grid {
  margin-top: 4% !important;
  margin-bottom: 4% !important;
}

.about-segment-2-segment {
  margin-left: 4% !important;
  margin-right: 4% !important;
  
}

.about-segment-2-header-1 {
  font-family: PonyonScript !important;
  font-size: 70px !important;
  margin: 0px !important;
}

.about-segment-2-container-1 {
  border: 15px #e4d9cf double;
  /*padding: 20px 40px 40px 40px !important;*/
  padding: 8% !important;
}

.about-segment-2-p {
  font-size: 20px !important;
  margin-top: 2%;
  line-height: 1.75em !important;
}

/* Segment 3*/
.about-segment-3-main-container{
  margin-top: 50px !important;
  margin-bottom: 30px !important;
}

.about-segment-3-header {
  font-family: Montserrat-regular !important;
  font-size: 50px !important;
}

.about-segment-3-grid-column, .about-segment-3-header-description {
  font-size: 18px !important;
}

.about-segment-3-grid-row-2 {
  margin-top: 3% !important;
}

/* Segment 4 */
.about-segment-4-header {
  font-family: PonyonScript !important;
  font-size: 50px !important;
  margin: 0px !important;
}

.about-segment-4-description {
  font-family: Montserrat-regular !important;
}

.family-note {
  text-align: left;
  padding: 10px !important;
  border-radius: 10px !important;

}

/* Music styles */
.music-header {
  margin-top: 8% !important;
  margin-bottom: 2% !important;
  font-size: 60px !important;
  font-family: Montserrat-regular !important;
}

.music-description {
  font-family: Montserrat-regular !important;
  color: gray !important;
  font-size: 25px !important;
  margin-bottom: 15% !important;
}
